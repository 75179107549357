import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Buffer } from 'buffer';
import { sendbirdSelectors, useSendbirdStateContext, withSendBird } from '@sendbird/uikit-react'
import { SendBirdState } from '@sendbird/uikit-react/types/lib/types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { useChannelContext } from '@sendbird/uikit-react/Channel/context'
import { GroupChannelCreateParams } from '@sendbird/chat/groupChannel'

import CircularLoader from '@/components/Loader/CircularLoader'
import { ChannelContext } from '@/context/ChannelContext'

import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'
import { FETCH_USER_WITH_MEMBERSHIP_INFO } from '@/graphql/users/queries'
import { SEND_ADMIN_MESSAGE } from '@/graphql/adminMessage/mutations'

import {
    getSessionUser,
    filterJoinedProviders,
    getChannelData,
    getChannelUserId,
    getUserSessionToken,
} from '@/utils'
import { IProviderData } from '@/utils/filterJoinedProviders/filterJoinedProviders'
import { TWO_MINUTES_IN_MILLISECONDS } from '@/core/constants'

import { IChatHeader } from './interfaces'

import { CustomButton } from '../ChannelHeader/styles'
import { ChatHeaderWrapper } from './styles'
import FlagCheckbox from './components/FlagCheckbox'
import { INVITE_TO_GROUP_CHANNEL } from '../../../graphql/chat/mutations';
import { datadogLogs } from '@datadog/browser-logs';

const UnmappedChatHeader: React.FC<IChatHeader> = ({
    sdk, // don't remove
    providerId,
    providerInAdminMessage,
    isCareTeamTab,
    providerRoleName,
    setRefetchChannelsToggle,
    setIsPatientLoading,
    setPatientUserId,
    setPatient,
    setSelectedChannel,
}: IChatHeader) => {
    const { isJoined, setisJoined } = useContext(ChannelContext)
    const [isLoadingMessage, setIsLoadingMessage] = useState(false)

    const globalStore = useSendbirdStateContext()
    const user = globalStore?.stores?.userStore?.user
    const sessionUser: any = getSessionUser()

    const { currentGroupChannel, initialized } = useChannelContext()
 
    const { members } = currentGroupChannel || {members: []};
    const userId = getChannelUserId(currentGroupChannel)

    const { data: userData, loading: userWithMembershipLoading } = useQuery(FETCH_USER_WITH_MEMBERSHIP_INFO, {
        variables: { userId: userId },
        pollInterval: TWO_MINUTES_IN_MILLISECONDS
    })

    const [sendAdminMessage] = useMutation(SEND_ADMIN_MESSAGE)
    const [fetchSignedUrlById] = useLazyQuery(DOWNLOAD_SIGNED_URL)
    const [inviteToGroupChannel] = useMutation(INVITE_TO_GROUP_CHANNEL)

    const isLoading = userWithMembershipLoading || !initialized

    const query = useMemo(() => {
        if (members.length === 2) {
            const currentPatient =
                members[1].userId === user.userId ? members[0].userId : members[1].userId
            const tokenForAuth = getUserSessionToken()
            const channelData = JSON.stringify({
                app_id: window.env.CHAT_APP_ID,
                user_id: user.userId,
                pacient_id: currentPatient,
                access_token: tokenForAuth,
            })
            return Buffer.from(channelData).toString('base64')
        }
    }, [members])

    const openLink = async () => {
        const currentChannelData = getChannelData(currentGroupChannel)
        const newChannelData = { ...currentChannelData, onVideoCall: true }
        const params: GroupChannelCreateParams = {}
        params.data = JSON.stringify(newChannelData)
        await currentGroupChannel?.updateChannel(params)
        window.open(`${window.env.CALL_SERVICE_ADDRESS}/?q=${query}`, '_blank')?.focus()
        setTimeout(async () => await sendAdminMessage({
            variables: {
                channelUrl: currentGroupChannel?.url,
                adminMessageInput: {
                    message: 'Video call started'
                }
            },
        }), 3000)
    }

    useEffect(() => {
        const { members } = currentGroupChannel || {}
        const userInChannel = members?.filter((member: any) => (member.userId === sessionUser.sub && member.state === 'joined')) || []
        if (userInChannel.length > 0) {
            setisJoined(true)
        } else {
            setisJoined(false)
        }
    }, [currentGroupChannel, user])

    const handleJoinChat = async () => {
        setIsLoadingMessage(true)

        try {
            if (currentGroupChannel) {
                await currentGroupChannel.acceptInvitation()
            
                try {
                    await sendAdminMessage({
                        variables: {
                            channelUrl: currentGroupChannel.url,
                            adminMessageInput: {
                                message: `${providerInAdminMessage} has joined the chat`,
                                sendPush: true,
                                markAsRead: true
                            }
                        }
                    })
                } catch (error) {
                    datadogLogs.logger.error('Error sending admin message (join chat)', error)
                }

                await updateChannelData()
                setisJoined(!isJoined)
                setRefetchChannelsToggle(true)
            }
        } catch (error) {
            datadogLogs.logger.error('Error joining chat', error)
        }

        setIsLoadingMessage(false)
    }

    const handleLeaveChat = async () => {
        try {
            if (currentGroupChannel) {
                await updateChannelData()

                // Nothing occurs on screen for providers if this fails - they will remain in/on the chat
                await currentGroupChannel.leave();

                setSelectedChannel('');

                try {
                    await sendAdminMessage({
                        variables: {
                            channelUrl: currentGroupChannel.url,
                            adminMessageInput: {
                                message: `${providerInAdminMessage} has left the chat`,
                                sendPush: true,
                                markAsRead: true
                            }
                        }
                    })
                } catch (error) {
                    datadogLogs.logger.error('Error sending admin message (leave chat)', error);
                }

                if (!isCareTeamTab) {
                    // Invite provider to chat after leaving in order for the provider to be able to rejoin
                    try {
                        await inviteToGroupChannel({
                            variables: {
                                channelUrl: currentGroupChannel.url,
                                userId: sessionUser.sub,
                            },
                        })
                    } catch (error) {
                        datadogLogs.logger.error('Error inviting to 1:1 group channel', error)
                    }
                }
                setisJoined(!isJoined)
                setRefetchChannelsToggle(true)
            }
        } catch (error) {
            datadogLogs.logger.error('Error leaving chat', error)
        }
    }

    const updateChannelData = async () => {
        try {
            const { data } = await fetchSignedUrlById({
                variables: {
                    downloadSignedUrlId: sessionUser.avatar,
                }
            })
            const currentChannelData = getChannelData(currentGroupChannel)
            const providerData: IProviderData = {
                doctor_id: providerId,
                firstName: sessionUser.firstName,
                role: providerRoleName
            }

            const members = filterJoinedProviders(currentChannelData, providerData, isJoined)
            const newUserData = !isJoined
                ? { ...currentChannelData,
                    ...providerData,
                    user_id: userId,
                }
                : { patient_id: currentChannelData.patient_id, user_id: userId }
            if(members?.length > 0) {
                newUserData.members = members
            }
            
            const params: GroupChannelCreateParams = {}
            params.data = JSON.stringify(newUserData)
            params.coverUrl = data?.downloadSignedUrl?.url || ''
            try {
                await currentGroupChannel?.updateChannel(params)
            } catch (error) {
                console.error('error updating channel', error)
            }
        } catch (error) {
            console.error('error updating channel data', error)
        }
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            setIsPatientLoading(isLoading)
        }

        return () => {
            mounted = false
        }
    }, [isLoading])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            setPatientUserId(userId)
        }

        return () => {
            mounted = false
        }
    }, [userId])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            setPatient(userData)
        }

        return () => {
            mounted = false
        }
    }, [userData])
    
    const isDisabledJoinBtn: boolean = isLoadingMessage

    return (
        <ChatHeaderWrapper>
            {isLoading ? <CircularLoader size={30} /> :
                <Box className='header__chat-block'>
                    {!isCareTeamTab && (
                        <CustomButton
                            className='video-call-btn'
                            onClick={openLink}
                            disabled={isDisabledJoinBtn || !isJoined}
                        >
                           Start Video Call
                        </CustomButton>
                    )}
                    {isCareTeamTab && (
                        <FlagCheckbox
                            channel={currentGroupChannel}
                            isDisabledJoinBtn={isDisabledJoinBtn}
                            isJoined={isJoined}
                        />
                    )}
                    <Box className='header__chat-btn'>
                        <CustomButton
                            className='leave-btn'
                            onClick={handleLeaveChat}
                            disabled={isDisabledJoinBtn || !isJoined}
                        >
                           Leave Chat
                        </CustomButton>
                        <CustomButton
                            className='join-btn'
                            onClick={handleJoinChat}
                            disabled={isDisabledJoinBtn || isJoined}
                        >
                           Join Chat
                        </CustomButton>
                    </Box>
                </Box>
            }
        </ChatHeaderWrapper>
    )
}

const mapStoreToProps = (store: SendBirdState) => {
    const sdk = sendbirdSelectors.getSdk(store)

    return {
        sdk,
    }
}

export const ChatHeader = withSendBird(UnmappedChatHeader, mapStoreToProps) as React.FC<IChatHeader>