import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import ProviderCard from '@/components/Provider/ProviderCard'
import ProviderPrimaryEditCard from '@/components/Provider/ProviderPrimaryEditCard'
import ProviderSecondaryEditCard from '@/components/Provider/ProviderSecondaryEditCard'
import CircularLoader from '@/components/Loader/CircularLoader'

import { FETCH_PROVIDER_BY_EMAIL } from '@/graphql/providers/queries'
import { getSessionUser } from '@/utils'

import { IProvider } from '@/components/Provider/interfaces'

import { DashboardProviderWrapper } from '@/pages/Dashboard/DashboardProviderPage/styles'

const DashboardProviderPage: React.FC = () => {
    const sessionUser: any = getSessionUser()

    const [provider, setProvider] = useState<IProvider | any>(sessionUser)

    const { loading, refetch } = useQuery(FETCH_PROVIDER_BY_EMAIL, {
        variables: {
            email: sessionUser?.email,
        },
        onCompleted: ({ providerByEmail }) => {
            setProvider(providerByEmail)
        },
    })

    useEffect(() => {
        refetch()
    }, [provider])

    return (
        <DashboardProviderWrapper>
            {loading ? (
                <CircularLoader size={60} />
            ) : (
                <>
                    <ProviderCard provider={provider} />
                    <ProviderPrimaryEditCard provider={provider} setProvider={setProvider} />
                    <ProviderSecondaryEditCard provider={provider} setProvider={setProvider} />
                </>
            )}
        </DashboardProviderWrapper>
    )
}

export default DashboardProviderPage
