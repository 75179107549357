import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CardHeader } from '@mui/material'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'

import PatientAvatar from '@/components/UI/PatientAvatar'
import { RedirectChatBtn, RedirectToCanvas } from '@/components/Chat/ChatHeader/styles'
import { IPatientContentHeader } from './interfaces'
import { PatientHeaderWrapper } from './styles'
import { getSubscriptionCurrentPlan } from '@/utils'

const PatientContentHeader: React.FC<IPatientContentHeader> = ({
    name,
    info,
    avatarLink,
    userId,
    patientId,
    subscriptionType,
}) => {
    const navigate = useNavigate()

    const redirectToCanvas = () => {
        window.open(`${window.env.CANVAS_MEDICAL}/patient/${patientId}`, '_blank')
    }

    const redirectToChat = () => {
        navigate('/dashboard/chats/care-team-chats', {
            replace: true,
            state: {
                name,
                id: userId,
            },
        })
    }

    return (
        <PatientHeaderWrapper>
            <CardHeader
                avatar={
                    <PatientAvatar
                        src={avatarLink}
                        currentPlan={getSubscriptionCurrentPlan(subscriptionType)}
                        fontSize={10}
                        wrapperWidth={76}
                        wrapperHeight={76}
                    />
                }
                subheader={
                    <Box className='header__patient-block'>
                        <Box>
                            <div className='header__patient-name'>{name}</div>
                            <div className='header__patient-info'>{info}</div>
                        </Box>

                        <Box>
                            <RedirectToCanvas onClick={redirectToCanvas}>
                                Open in Canvas
                            </RedirectToCanvas>
                            <RedirectChatBtn
                                onClick={redirectToChat}
                                startIcon={<ForumOutlinedIcon />}
                            >
                                Chat
                            </RedirectChatBtn>
                        </Box>
                    </Box>
                }
                sx={{ marginLeft: '5px' }}
            />
        </PatientHeaderWrapper>
    )
}

export default PatientContentHeader
