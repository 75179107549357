import { styled, Button, Box } from '@mui/material'

export const EditForm = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .todo-inputs,
    .MuiAutocomplete-input {
        margin-bottom: 8px;
        font-size: 14px;
    }

    & .MuiAutocomplete-input {
        padding: 0;
        margin-bottom: 0;
    }

    .MuiSelect-select {
        padding-left: 12px;
    }

    .MuiOutlinedInput-root .MuiAutocomplete-input {
        padding: 0;
        padding-left: 6px;
    }
`

export const EditButtonConfirm = styled(Button)(
    ({ theme }) => `
    width: 343px;
    height: 48px;
    padding: 6.5px 0;
    align-self: center;
    background-color: ${theme.palette.violet.main};
    color: white;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 500;
    border-radius: 6px;

    &.edit-college-btn {
        width: 120px;
    }

    &.edit-admin-message-btn {
        width: 120px;
        margin-left: 16px;
    }

    &.Mui-disabled {
      background-color: #a0aeba;
      color: #edebe5;
    }
  `
)
