import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Box, CardHeader } from '@mui/material'

import EditModeBtn from '@/components/UI/Buttons/EditModeBtn'
import { InputField, TextareaField } from '@/components/UI'

import { UPDATE_PROVIDER } from '@/graphql/providers/mutations'

import { IProvider, IProviderEditCard } from '@/components/Provider/interfaces'

import { ProviderCardWrapper } from '@/components/Provider/styles'
import { CardContentBox } from '@/components/Patient/PatientContent/styles'

const ProviderSecondaryEditCard: React.FC<IProviderEditCard> = ({ provider, setProvider }) => {
    const [providerEditData, setProviderEditData] = useState<IProvider | any>({})
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [updateProvider] = useMutation(UPDATE_PROVIDER)

    const filteredProviderData = useMemo(() => {
        let phoneNumber = provider?.phoneNumber

        if (provider?.phoneNumber?.startsWith('+1')) {
            phoneNumber = provider?.phoneNumber?.substring(2)
        }

        return {
            firstName: provider.firstName,
            lastName: provider.lastName,
            avatar: provider.avatar,
            role: provider.role,
            state: provider.state,
            credentials: provider.credentials,
            licensedStates: provider.licensedStates,
            education: provider.education,
            background: provider.background,
            phoneNumber: phoneNumber,
            smsNotification: provider.smsNotification,
        }
    }, [provider])

    useEffect(() => {
        sessionStorage.setItem('sessionUser', JSON.stringify(provider))

        setProviderEditData(filteredProviderData)
    }, [provider])

    const enableEditMode = () => {
        setIsEditMode(!isEditMode)
    }

    const cancelEditingMode = () => {
        setProviderEditData(filteredProviderData)
        setIsEditMode(!isEditMode)
    }

    const sendModifiedData = async () => {
        try {
            await updateProvider({
                variables: {
                    updateProviderId: provider.id,
                    providerInput: {
                        ...providerEditData,
                        role: {
                            id: providerEditData?.role?.id,
                        },
                    },
                },
            })

            // any keys that match between the provider and providerEditData objects
            // will be overwritten by the values in providerEditData
            setProvider({
                ...provider,
                ...providerEditData,
            })

            sessionStorage.setItem(
                'sessionUser',
                JSON.stringify({
                    ...provider,
                    ...providerEditData,
                })
            )

            setIsEditMode(false)
        } catch (e) {
            console.error(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const editProviderData = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const isLessThanOrEqualToLength = (fieldLength: number) => {
            return value.length <= fieldLength
        }

        switch (name) {
            case 'credentials':
            case 'licensedStates':
                if (!value?.length || (value?.length && isLessThanOrEqualToLength(50))) {
                    setProviderEditData((prevState: IProvider) => ({
                        ...prevState,
                        [name]: value,
                    }))
                }
                break
            case 'education':
                if (!value?.length || (value?.length && isLessThanOrEqualToLength(150))) {
                    setProviderEditData((prevState: IProvider) => ({
                        ...prevState,
                        [name]: value,
                    }))
                }
                break
            case 'background':
                if (!value?.length || (value?.length && isLessThanOrEqualToLength(300))) {
                    setProviderEditData((prevState: IProvider) => ({
                        ...prevState,
                        [name]: value,
                    }))
                }
                break
            default:
                setProviderEditData((prevState: IProvider) => ({
                    ...prevState,
                    [name]: value,
                }))
        }
    }

    return (
        <ProviderCardWrapper className='edit-card'>
            <CardHeader
                action={
                    <EditModeBtn
                        isEditMode={isEditMode}
                        cancelEditingMode={cancelEditingMode}
                        sendModifiedData={sendModifiedData}
                        enableEditMode={enableEditMode}
                        isLoading={isLoading}
                        isEmptyField={false}
                        isDisabled={false}
                    />
                }
                subheader={
                    <Box className='provider-edit__wrapper'>
                        <CardContentBox>
                            <InputField
                                label='Credentials (max 50 characters)'
                                name='credentials'
                                value={providerEditData?.credentials}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                onChangeHandler={editProviderData}
                                classNames={{
                                    typographyClass: 'secondary-edit-provider-input-label',
                                }}
                            />
                        </CardContentBox>
                        <CardContentBox>
                            <InputField
                                label='Licensed States (max 50 characters)'
                                name='licensedStates'
                                value={providerEditData?.licensedStates}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                onChangeHandler={editProviderData}
                                classNames={{
                                    typographyClass: 'secondary-edit-provider-input-label',
                                }}
                            />
                        </CardContentBox>
                        <CardContentBox>
                            <TextareaField
                                label='Education (max 150 characters)'
                                name='education'
                                value={providerEditData?.education}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                onChangeHandler={editProviderData}
                                classNames={{
                                    typographyClass: 'secondary-edit-provider-input-label',
                                    inputClass: `secondary-edit-provider-input-textarea${
                                        isEditMode ? '' : ' disabled'
                                    }`,
                                }}
                            />
                        </CardContentBox>
                        <CardContentBox>
                            <TextareaField
                                label='Background (max 300 characters)'
                                name='background'
                                value={providerEditData?.background}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                onChangeHandler={editProviderData}
                                classNames={{
                                    typographyClass: 'secondary-edit-provider-input-label',
                                    inputClass: `secondary-edit-provider-input-textarea${
                                        isEditMode ? '' : ' disabled'
                                    }`,
                                }}
                            />
                        </CardContentBox>
                    </Box>
                }
            />
        </ProviderCardWrapper>
    )
}

export default ProviderSecondaryEditCard
